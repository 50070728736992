<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="contents-header">
      <p class="breadcrumb-menu">
        <router-link :to="{name: 'AccountSettings'}" class="ashen-link">アカウント設定</router-link>
        <i class="fas fa-angle-right form-control-color"></i> PCR検査変更
      </p>
    </div>
    <div class="card text-first border-0 border-top">
      <div class="card-body max-800 mx-auto col-12">
        <div class="row">
          <h3 class="fw-bold mb-4 mt-sm-2">PCR検査変更</h3>
          <div class="col">
            <div class="form-group mt-3">
              <label>PCR検査</label>
              <div class="mt-2 mx-auto">
                <div class="form-check form-check-inline col-12 col-sm-3">
                  <input id="inlineRadio14" v-model="latestPcrTestResult" :value=Number(1)
                         class="form-check-input"
                         name="isSuspectInfected"
                         type="radio">
                  <label class="form-check-label" for="inlineRadio14">陽性</label>
                </div>
                <div class="form-check form-check-inline col-12 col-sm-3">
                  <input id="inlineRadio15" v-model="latestPcrTestResult" :value=Number(-1)
                         class="form-check-input"
                         name="isSuspectInfected"
                         type="radio">
                  <label class="form-check-label" for="inlineRadio15">陰性</label>
                </div>
                <div class="form-check form-check-inline col-12 col-sm-3">
                  <input id="inlineRadio16" v-model="latestPcrTestResult" :value=Number(0)
                         class="form-check-input"
                         name="isSuspectInfected"
                         type="radio">
                  <label class="form-check-label" for="inlineRadio16">未検査</label>
                </div>
              </div>
              <div v-show="errors['latestPcrTestResult']" id="err_pcrTest" class="form-error">{{
                  errors['latestPcrTestResult']
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col">
            <label for="exampleInputPassword1">最後に検査した日</label>
            <div class="input-group">
              <Calender :currentDate="latestPcrTestDate"
                        :disable="latestPcrTestResult === 0"
                        :type="'pcr'"
                        @update="updateDate"
              ></Calender>

              <!--              <input v-model="latestPcrTestDate"-->
              <!--                     :disabled="latestPcrTestResult === 0"-->
              <!--                     class="form-control mt-2 full-width-input"-->
              <!--                     maxlength="10"-->
              <!--                     placeholder="yyyy/mm/ddで入力"-->
              <!--                     type="text"-->
              <!--                     @keydown="onKeyDown"/>-->
              <!--              <div class="input-group-append mt-2">-->
              <!--                <input :disabled="latestPcrTestResult === 0" :value="latestPcrTestDate"-->
              <!--                       class="form-control datepicker"-->
              <!--                       type="date"-->
              <!--                       @change="getPcrTestResultDateFormat"-->
              <!--                >-->
              <!--              </div>-->
            </div>
          </div>
          <div v-show="errors['latestPcrTestDate']" class="form-error">{{ errors['latestPcrTestDate'] }}</div>
        </div>

        <!--                <div class="row">-->
        <!--                    <label class="mt-3 mb-2">-->
        <!--                        最後に検査した日-->
        <!--                    </label>-->
        <!--                    <div class="input-group mb-3">-->
        <!--                        <input type="date" pattern="yyyy-mm-dd" class="form-control" v-model="latestPcrTestDate">-->
        <!--                    </div>-->
        <!--                    <div class="form-error" id="err_lastInspectionDate" v-show="errors['latestPcrTestDate']">{{ errors['latestPcrTestDate'] }}</div>-->
        <!--                </div>-->
        <div class="row mt-4">
          <div class="mt-sm-4 col col-sm-6 order-1 order-sm-0">
            <button class="btn btn-light bg-white w-100 mw-100 ashen-link text-decoration-none light border-0 custom-btn"
                    @click="$router.push('account')">
              キャンセル
            </button>
          </div>
          <div class="col col-sm-6 order-0 order-sm-1">
            <button class="btn btn-primary w-100 mw-100 ok btn-outline-light font-weight-bold mt-4 custom-btn"
                    v-on:click="doChange">
              変更
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SetAdminUpdateUser} from "jsadminclient";
import Common from "jsadminclient/common";
import {mapGetters} from "vuex";
import Calender from "../components/layouts/Calendar";

export default {
  name: "PcrTestChange",
  components: {Calender},
  props: {
    pcrTestData: {type: Object, default: null}
  },
  data() {
    return {
      latestPcrTestResult: 0,
      latestPcrTestDate: '',
      pcrTestResultDate: '',
      errors: []
    }
  },
  watch: {
    'latestPcrTestResult': function (newValue, oldValue) {
      this.latestPcrTestDate = newValue !== 0 ? this.pcrTestResultDate : ''
    }
  },
  created() {
    this.pcrTestResultDate = Common.dateFormatConvert(this.pcrTestData.latestPcrTestDate)
    this.latestPcrTestResult = this.pcrTestData.latestPcrTestResult
  },
  computed: {
    ...mapGetters(['config']),
    latestPcrTestDateForPicker() {
      if (this.latestPcrTestDate === null) {
        return null
      }
      return Common.dateFormatConvertForDatePicker(this.latestPcrTestDate)
    }
  },
  methods: {
    getPcrTestResultDateFormat(e) {
      this.pcrTestResultDate = moment(e.target.value).format('YYYY/MM/DD');
      this.latestPcrTestDate = this.pcrTestResultDate
    },
    createSetUpdateUser(step) {
      var params = new SetAdminUpdateUser()
      params.initialUpdate = false
      params.authenticationPassword = ''
      params.firstName = ''
      params.firstNameUpdate = false
      params.lastName = ''
      params.lastNameUpdate = false
      params.password = ''
      params.passwordConfirm = ''
      params.passwordUpdate = false
      params.email = ''
      params.emailUpdate = false
      params.birthday = ''
      params.birthdayUpdate = false
      params.gender = 1;
      params.genderUpdate = false
      params.nationalityId = 1
      params.nationalityIdUpdate = false
      params.address = ''
      params.addressUpdate = false
      params.phoneNo = ''
      params.phoneNoUpdate = false
      params.hasCoronaVaccination = false
      params.latestCoronaVaccinationDate = ''
      params.coronaVaccinationUpdate = false
      params.latestPcrTestResult = this.latestPcrTestResult
      params.latestPcrTestDate = Common.dateFormatConvert(this.latestPcrTestDate)
      params.latestPcrTestUpdate = true

      return params
    },
    isAllowedKeyCodes(keyCode) {
      const allowedKeyCodes = [8, 46, 111, 191]
      for (let i = 48; i < 58; i++) {
        allowedKeyCodes.push(i)
      }
      for (let i = 96; i < 106; i++) {
        allowedKeyCodes.push(i)
      }
      return allowedKeyCodes.includes(keyCode)
    },
    onKeyDown(event) {
      const keyCode = event.keyCode
      const len = this.latestPcrTestDate.length
      if (!this.isAllowedKeyCodes(keyCode)) {
        event.preventDefault()
      }
      if (len !== 4 || len !== 7) {
        if (keyCode === 111) {
          event.preventDefault()
        }
      }
      if (len === 4 && keyCode !== 8 && keyCode !== 46) {
        this.latestPcrTestDate += '/'
      }
      if (len === 7 && keyCode !== 8 && keyCode !== 46) {
        this.latestPcrTestDate += '/'
      }
    },
    async doChange(step) {
      this.errors = [];

      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const setUpdateUser = this.createSetUpdateUser(step)
        const jsonObject = await api.usersUserIdUpdatePostAsync(user_id, setUpdateUser, 0)

        Common.showToast("PCR検査が変更されました。")
        this.$router.push({name: 'AccountSettings'});
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)

        this.latestPcrTestResult = jsonObject.latestPcrTestResult
        this.latestPcrTestDate = Common.dateFormatConvert(jsonObject.latestPcrTestDate)
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    updateDate(date) {
      this.latestPcrTestDate = date
    },
  },
  mounted() {
    this.getUser()
  }
}

</script>

<style lang="scss" scoped>
.fa-angle-right {
  width: 15px;
  height: auto;
  color: #888888;
}

.datepicker {
  &::-webkit-calendar-picker-indicator {
    background: url("../assets/images/calendar-event.svg") no-repeat;
  }
}

:disabled {
  background: #FAF7F9 !important;
}

@media only screen and (max-width: 576px) {
  .topic_path {
    display: none;
  }
  .border-top {
    border: 0px !important;
  }
}

.content {
  &.pcrtestchange {
    .form-control {
      border-style: none;
    }
  }
}
</style>
